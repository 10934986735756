<template>
  <div class="news-page">
    <el-button
      type="primary"
      class="btnAdd"
      @click="dialogVisible = true"
      v-show="$store.state.btn_if.indexOf('system_hot_word_add') != -1"
      >新增词条</el-button
    >
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="hwId" width="100" label="编号" sortable="custom">
        </el-table-column>
        <el-table-column prop="hwName" label="名称"> </el-table-column>
        <!-- <el-table-column
          prop="content"
          label="内容"
          width="300"
          :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <!-- <el-table-column prop="picture" label="配图">
          <template slot-scope="ew">
            <img :src="baseurl+ ew.row.logo" alt="" style="height: 150px" />
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="url" label="链接">
          <template slot-scope="ew">
            <a :href="ew.row.url" target="“_blank”">{{ew.row.url}}</a>
          </template>
        </el-table-column> -->
        <el-table-column prop="hwCreateTime" label="时间" sortable="custom">
        </el-table-column>
        <el-table-column prop="hwOrderNum" label="排序" sortable="custom">
          <template #header>
            <span>排序</span>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                :ref="'INPUTFOCUS' + $index"
                v-model="row.hwOrderNum"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row)"
              />
            </div>
            <span
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('system_hot_word_order') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('system_hot_word_order') != -1 &&
                  showinput(row, 'INPUTFOCUS' + $index)
              "
            >
              {{ row.hwOrderNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="ew">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="edit(ew.row.hwId)"
              class="skyblue"
              v-show="$store.state.btn_if.indexOf('system_hot_word_edit') != -1"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="open(ew.row.hwId)"
              class="red"
              v-show="
                $store.state.btn_if.indexOf('system_hot_word_delete') != -1
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="新增词条" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="form.orderNum" :min="0"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false
            form = {}
            form.orderNum = 0
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="词条修改" :visible.sync="dialogVisiblexui" width="50%">
      <el-form ref="form" :model="form_1" label-width="80px">
        <el-form-item label="名称">
          <el-input v-model="form_1.name"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="form_1.orderNum" :min="0"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblexui = false">取 消</el-button>
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="快讯列表" :visible.sync="diokuai" width="50%">
      <el-table
        :data="lista"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @selection-change="sel"
      >
        <!-- <el-table-column prop="id" width="100" label="编号"> </el-table-column> -->
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="title" label="标题" width="180">
          <template slot-scope="{ row }">
            <p>{{ row.title }}</p>
            <a :href="row.originLink">(原文链接)</a>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" width="380">
        </el-table-column>
        <el-table-column prop="source" label="来源"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPagea"
        :total="totala"
        :page-size="pagesizea"
        @size-change="handleSizeChangea"
        @current-change="handleCurrentChangea"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diokuai = false">取 消</el-button>
        <el-button type="primary" @click="addskuai">{{
          btn ? '添 加' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  // 111
  data() {
    return {
      action: {
        action: 'ad'
      },
      btn: true,
      currentPage: 1,
      pagesize: 10,
      currentPagea: 1,
      pagesizea: 5,
      list: [],
      value: '',
      totala: 0,
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: process.env.VUE_APP_STATIC,
      total: 0,
      order: '',
      form: {
        name: '',
        orderNum: 0
      },
      form_1: {
        logo: ''
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      diokuai: false,
      lista: [],
      fastid: '',
      paiform: {}
    }
  },
  updated() {
    //   console.log(this.form);
  },
  methods: {
    async handleInputBlur(row) {
      // for (var s in row) {
      //   if (s != 'pai') {
      //     s = s.slice(2)
      //     s = s.replace(s[0], s[0].toUpperCase());
      //   }
      // }

      this.paiform = {
        id: row.hwId,
        name: row.hwName,
        orderNum: row.hwOrderNum
      }
      try {
        const { data: res } = await this.$http.post(
          '/admin/HotWord/edit',
          this.paiform
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          //  row.pai=false
          //  this.tableData=[...this.tableData]
          this.getlist()
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        // this.$message.error(e)
      }
      console.log(this.tableData)
    },
    showinput(row, refName) {
      console.log(row, refName, this.$refs[refName])
      row.pai = true
      this.list = [...this.list]
      setTimeout(() => {
        $('.id input').focus()
      }, 0)
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'hwCreateTime') {
          s = 'hw_create_time'
        } else if (c.prop == 'hwOrderNum') {
          s = 'hw_order_num'
        } else {
          s = 'hw_id'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },

    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    handleSizeChangea(e) {
      this.pagesizea = e
      console.log(e)
    },
    handleCurrentChangea(e) {
      this.currentPagea = e
      this.getlista()
    },
    getlist() {
      this.$http
        .get('/admin/HotWord/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            console.log(this.list)
            this.total = res.data.totalCount
          } else {
            this.$message.error(res.message)
          }
        })
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.logo = res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    sel(val) {
      console.log(val)
      var se = val.map(item => item.id)
      console.log(se)

      this.fastid = se.join(',')
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.logo = res.data.url
        // console.log(this.form_1);
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/HotWord/add', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisible = false
              location.reload()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    addedit() {
      if (this.btn) {
        this.$http
          .post('/admin/HotWord/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblexui = false
              this.getlist()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      // this
      this.dialogVisiblexui = true
      this.form_1.id = id
      this.$http
        .get('/admin/HotWord/getById?id=' + id)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form_1 = {
              name: res.data.hwName,
              orderNum: res.data.hwOrderNum,
              id: id
            }
          } else {
            this.$message.error(res.message)
          }
        })
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/HotWord/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/News/publish', { id: id, publishStatus: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addkuai() {
      this.diokuai = true
      this.getlista()
    },
    getlista() {
      this.$http
        .get('/admin/NewsTemp/getList', {
          params: { page: this.currentPagea, pageSize: this.pagesizea }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.lista = res.data.list
            this.totala = res.data.totalCount
            console.log(this.currentPagea)
            console.log(this.pagesizea)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addskuai() {
      if (this.btn) {
        this.$http
          .post('/admin/News/addAll', { ids: this.fastid })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.diokuai = false
              location.reload()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  },
  created() {
    this.getlist()
    sessionStorage.setItem('action', 'news')
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
</style>
<style lang="less"></style>
